/* eslint-disable camelcase */

import React from 'react'

// Local import
import TextField from '../BasicFields/TextField'
import SelectionOption from './SelectionOption'
import { updateErrors } from '../SectionField'

// Use an counter to avoid duplicate id generation
let idCounter = 0
const idGenerator = () => `new-${new Date().getTime() + idCounter++}`

class Selection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.question.id,
      context: props.question.context,
      position: props.question.position,
      title: props.question.title,
      mandatory: props.question.mandatory,
      options: props.question.options,
      removed_option_ids: [],
      errors: props.question.errors || {}
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    this.state.errors = updateErrors(this.state.errors, this.props.getErrors)

    return (
      this.state.title !== nextState.title ||
      this.state.mandatory !== nextState.mandatory ||
      this.state.position !== nextState.position ||
      this.state.options.length !== nextState.options.length ||
      this.state.errors.isNew === true
    )
  }

  componentDidUpdate() {
    this.props.handleQuestionChange(this.state)
  }

  handleChange = event => this.setState({ [event.target.name]: event.target.value })

  handleMandatoryChange = event => this.setState({ mandatory: event.target.checked })

  handleClickNewOption = () => {
    this.setState(prevState => ({ options: prevState.options.concat({ id: idGenerator(), content: '' }) }))
  }

  handleRemoveOption = optionId => () => {
    this.setState(prevState => {
      let newlyRemovedOption = []
      const options = prevState.options.filter(option => option.id !== optionId)

      if (!String(optionId).includes('new-'))
        newlyRemovedOption = prevState.removed_option_ids.concat(optionId)

      return { options, removed_option_ids: newlyRemovedOption }
    })
  }

  handleOptionChange = index => option => {
    this.setState(prevState => {
      const { options } = prevState

      options[index] = option

      return { options }
    })
  }

  render() {
    return (
      <div className="nested-fields ml-2 ml-md-4">
        <div className="d-flex">
          <div className="mr-auto">
            <i className="fas fa-arrows-alt c-pointer" {...this.props.dragHandleProps} />
            {' Question avec choix multiples '}
            <i
              className="fas fa-clone text-secondary c-pointer"
              onClick={this.props.handleQuestionDuplicate(this.state.id)}
            />
          </div>
          <i
            className="fas fa-trash danger-hover c-pointer"
            onClick={this.props.handleQuestionRemove(this.state.id)}
          />
        </div>
        <TextField
          name="title"
          value={this.state.title}
          placeholder="Titre de la question..."
          errors={this.state.errors.title}
          onChange={this.handleChange}
        />
        <div className="custom-control custom-switch mb-2">
          <input
            id={`mandatory-question-${this.state.id}`}
            type="checkbox"
            className="custom-control-input custom-switch-lg"
            checked={this.state.mandatory}
            onChange={this.handleMandatoryChange}
          />
          <label className="custom-control-label" htmlFor={`mandatory-question-${this.state.id}`}>
            Réponse obligatoire
          </label>
        </div>
        {
          this.state.options.map((option, index) => (
            <SelectionOption
              key={option.id}
              option={option}
              handleOptionChange={this.handleOptionChange(index)}
              handleRemoveOption={this.handleRemoveOption(option.id)}
            />
          ))
        }
        <button type="button" className="btn btn-outline-primary" onClick={this.handleClickNewOption}>
          Ajouter une option
        </button>
      </div>
    )
  }
}

export default Selection
