import React from 'react'

// Local import
import TextField from '../BasicFields/TextField'

export default class SelectionOption extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      id: props.option.id,
      content: props.option.content
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    return this.state.content !== nextState.content
  }

  componentDidUpdate() {
    this.props.handleOptionChange(this.state)
  }

  handleChange = event => this.setState({ [event.target.name]: event.target.value })

  render() {
    return (
      <>
        <div className="nested-fields ml-2 ml-md-4" hidden={this.props.hidden}>
          <i className="far fa-circle option-circle" />
          <div className="option-field">
            <TextField name="content" value={this.state.content} onChange={this.handleChange} />
          </div>
          <div className="option-trash">
            <i className="fas fa-trash danger-hover c-pointer" onClick={this.props.handleRemoveOption} />
          </div>
        </div >
        <div className="clearfix" />
      </>
    )
  }
}
